import React from 'react'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import AllWebinars from '../../components/allWebinars'
import { Button } from '../../components/global'

const W008 = () => (
  <Layout includeNotice={false}>
    <SEO title="Webinar 008: Relays, Part 2 of (2)" />
    <div className="container container-last">
      <h1>Webinar 008: Relays, Part 2 of (2)</h1>
      <hr />
      <p class="boost">"How to Troubleshoot Relay and Solid-State Relay Circuits.”</p>
      <p>
        In this webinar learn what to check first if a mechanical relay CLICKS or DOES NOT CLICK
        then using a DMM and following the fool-proof step-by-step troubleshooting procedures
        outlined in this webinar to quickly identify the relay circuit problem.
      </p>
      <p>
        Actual circuit voltage readings are given to identify all the ways a computer controlled
        mechanical relay circuit can fail. Identify what is wrong in a relay circuit when the relay
        is controlled by two different on-board computers at the same time.
      </p>
      <p>
        <strong>
          Expect to begin to encounter solid-state relays in the growing fleets of electric autos,
          trucks and H.D.
        </strong>
      </p>
      <p>
        This webinar covers troubleshooting solid-state relay (SSR) circuits with fool-proof
        step-by-step troubleshooting procedures. Follow these test steps with your DMM and you will
        find the problem. Learn how to tell if an SSR is “ON” or “OFF” and if the SSR is good when
        “ON.” Learn how to identify SSR device failures. You will know how to tell if the problem is
        with the SSR itself or the problem is in the rest of the circuit.
      </p>
      <p>
        Have your webinar handout ready to take copious notes and record DMM readings to guide you
        through your next SSR circuit problem.
      </p>
      <ul style={{ marginBottom: 0 }}>
        <li>Watch Webinar W008 Session</li>
        <li>
          Purchase a link to download the Webinar W008 recording and watch as often as you like.
          (Download link expires after 7 days)
        </li>
      </ul>
      <Button
        type="secondary"
        text="BUY NOW for $39.00"
        href="http://www.1shoppingcart.com/SecureCart/SecureCart.aspx?mid=2169DBD4-F088-4CA4-A011-56C1593411AC&pid=e5d705e3c39e463481b220ba07a008fa"
      />

      <hr />
      <AllWebinars />
    </div>
  </Layout>
)

export default W008
